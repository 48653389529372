import {getOrCreateDataObject} from 'o365.vue.ts';
import AzApi from 'o365.modules.azure.storage.api.ts';
const declarations = new Map();

const BlobClient = AzApi.BlobClient.StaticFiles;

const components = {
    
    "o365.vue.components.DataLookup.vue":"ODataLookup",
    "o365.vue.components.DataGrid.vue":"ODataGrid",
    "o365.vue.components.DataTable.vue":"ODataTable",
    'o365.vue.components.Action.Save.vue':"OActionSave",
    'o365.vue.components.Action.Delete.vue':"OActionDelete",
    'o365.vue.components.Action.Cancel.vue':"OActionCancel",
    'o365.vue.components.ColContainer.vue':"OColContainer",
    'o365.vue.components.RowContainer.vue':"ORowContainer",
    'o365.vue.components.SizerPanel.vue':'OSizerPanel',
    'o365.vue.components.ONavbar.vue':"ONavbar",
    'o365.vue.components.DataGrid.Column.jsx':"OColumn",
    'o365.vue.components.Tabs.vue':"OTabs",
    'o365.vue.components.Tab.vue':"OTab",
    'o365.vue.components.Form.vue':"OForm",
    'o365.vue.components.FormControl.vue':"OFormControl",
    'o365.vue.components.Modal.vue':"OModal",
    'o365.vue.components.DatePicker.vue':"ODatePicker",
    'o365.vue.components.TextArea.vue':"OTextArea",
    'o365.vue.components.MatchMedia.vue':"OMatchMedia",
    'o365.vue.components.MediaQueryProvider.vue':"OMediaQueryProvider",
   // 'o365.vue.components.OrgUnitsLookup.vue':"OOrgunitsLookup",

}
//filename,LimitToSlots, SelfClosing
const componentsAray = [
    ['o365.vue.components.DataGrid.Column.jsx',"OColumn",true,true],
    ["o365.vue.components.DataLookup.vue","ODataLookup",true,false],
    ["o365.vue.components.DataGrid.vue","ODataGrid",true,false],
    ['o365.vue.components.Action.Save.vue',"OActionSave",false,true],
    ['o365.vue.components.Action.Delete.vue',"OActionDelete",true,true],
    ['o365.vue.components.Action.Cancel.vue',"OActionCancel",true,true],
    ['o365.vue.components.ColContainer.vue',"OColContainer",false,false],
    ['o365.vue.components.RowContainer.vue',"ORowContainer",false,false],
    ['o365.vue.components.SizerPanel.vue','OSizerPanel',false,false],
    ['o365.vue.components.ONavbar.vue',"ONavbar",true,true],
    ['o365.vue.components.Tabs.vue',"OTabs",true,false],
    ['o365.vue.components.Tab.vue',"OTab",false,false],
    ['o365.vue.components.Form.vue',"OForm",false,false],
    ['o365.vue.components.FormControl.vue',"OFormControl",false,false],
    ['o365.vue.components.Modal.vue',"OModal",false,false],
    ['o365.vue.components.DatePicker.vue',"ODatePicker",true,true],
    ['o365.vue.components.TextArea.vue',"OTextArea",true,true],
    ['o365.vue.components.MatchMedia.vue',"OMatchMedia",false,false],
    ['o365.vue.components.MediaQueryProvider.vue',"OMediaQueryProvider",false,false]

]

const dsComponenents = getOrCreateDataObject({
    id:"o_dsComponenents_CodeBuilder",
    fields:[{name:"FileName"},{name:"ComponentName"}]
})


dsComponenents.enableClientSideHandler(Object.keys(components).map(key=>{return{
    FileName:key,
    ComponentName:components[key]
}}));

const ignoredColumnProps = {
    ODataLookup:['colId','column','editable','cellrenderer','celleditor',
        'cellrendererparams','celleditorparams','suppressNavigable',
        'bulkUpdate','bulkUpdateApplyFunction',
        'flexwidth','singleClickEdit','dblclickHandler',
        'aggregate','getCopyValue','editorClass','groupAggregate','summaryAggregate','filter']
}

const autoAddProps = {
    ODataLookup:[
        {name:"class",value:"form-select form-select-sm",description:"Use this prop only outside grid"},
        {name:"placeholder",value:"Choose..",description:"Use this prop only outside grid",type:"Function",translate:true},
    ],
    input:[
        {name:"class",value:"form-control form-control-sm"},
        {name:"v-model",value:"dsObject.current.SomeField"},
    ]
}

function isDefaultSlotText(pFileName:string){
    const vComponentName = getComponentName(pFileName);
    const vSlots = ["OActionDelete","OActionCancel","OActionSave"];

    return vSlots.indexOf(vComponentName) > -1;
}

const childComponents = {
    'o365.vue.components.Tabs.vue':["OTab"],
    'o365.vue.components.FormControl.vue':["input","ODatePicker","ODataLookup"],
}

const defaultSlots = {
    ODataLookup:{name:"OColumn",type:"Array"}
}

const inlineHtml = ["o365.vue.components.DataGrid.Column.jsx"];

const hasColumnsSlotArray = ["o365.vue.components.DataLookup.vue","o365.vue.components.DataGrid.vue","o365.vue.components.Form.vue","o365.vue.components.DataTable.vue"];
function getColumnsSlotsComponent(pFileName:string){
    const columnsSlotsComponents = [
        {component:"ODataLookup", child:"OColumn"},
        {component:"ODataGrid", child:"OColumn"},
        {component:"OForm", child:"OFormControl"},
       
    ];
    const vComponentName = getComponentName(pFileName);
    const vFound = columnsSlotsComponents.find(x=>x.component === vComponentName);
    if(vFound){
        return getFileNameForComponent(vFound.child);
    }
    return null;

    
}


function hasColumnsSlot(pFileName:string){
    return hasColumnsSlotArray.indexOf(pFileName) > -1;
}

function isAlwaysFunctionProp(pProp:string){
    return ["dataObject"].indexOf(pProp) > -1;
}

function getIgnoredColumnProps(pFilename:string){
    if(!components[pFilename]) return [];

    return ignoredColumnProps[components[pFilename]]??[];
}
function getAutoAddProps(componentName:string){
   // if(!components[pFilename]) return [];
    return autoAddProps[componentName]??[];
}

async function getDocDeclaration(pFileName:string){
    if(declarations.has(pFileName)){
        return declarations.get(pFileName);
    }

    const vContent = await getContent(pFileName);
    let vDoc = null;
    let vContentRes = vContent[0].ContentTest;
   // if(!vContentRes){
    const vVersion =  vContent[0].Version;
    const vRes  = await getContentFromAzure(pFileName,vVersion);
    if(vRes['Content']) vContentRes = vRes['Content'];
    
    if(vRes['Documentation']) vDoc = JSON.parse(vRes['Documentation']);
    if(vDoc) vDoc = vDoc.results;
  //  }
    if(!vDoc)
    vDoc = await getDeclaration(pFileName,vContentRes);

    declarations.set(pFileName,vDoc);

    return vDoc;

}

function getFileNameForComponent(pComponent:string){
    let vFileName = null;
    Object.keys(components).forEach(key=>{
        if(components[key].toLowerCase() === pComponent.toLowerCase()){
            vFileName = key;
            return;
        }
    })

    return vFileName;
}

async function getDeclaration(pFileName:string,pContent:string){
    return await fetch('https://omega365nodeserviceapp.azurewebsites.net/api/vue-docgen', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            
        },
        body: JSON.stringify({
            fileName:pFileName,
            source:pContent
        })
    }).then(res=>res.json()).then(res=>res.result).catch(ex=>{
        console.log(ex);
    });
}

 async function getContent(pFileName:string){
    return await fetch(`api/data/declarations`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
                "accept": "application/json"
        },
        body:JSON.stringify({
            operation:'retrieve',
            fields:[{name:"ContentTest"},{name:"Version"}],
            viewName:'stbv_O365_StaticFiles',
            whereClause:`[ID] = '${pFileName}'`,
            maxRecords:1
            
        })
    }).then(res=>res.json()).then(res=>res.success);
}



async function compileVue(pFileName:string,pContent:string){
    return await fetch("https://omega365nodeserviceapp.azurewebsites.net/api/VueSFCCompiler", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            source:pContent,
            fileName: pFileName,
            inlineTemplate: false
        })
        }).then(res=>res.json());
}

async function getContentFromAzure(pFileName:string,pVersion){
    return await BlobClient.getStaticFile({fileNameWithPath: `O365/site/scripts/${pFileName}/${pVersion}/${pFileName}`});

}

function getComponentName(pFileName:string){
    return components[pFileName];
}




export  {components,ignoredColumnProps,getIgnoredColumnProps,getAutoAddProps,getDocDeclaration,hasColumnsSlot,compileVue,inlineHtml,dsComponenents,isAlwaysFunctionProp,childComponents,getFileNameForComponent,getComponentName,getColumnsSlotsComponent};